<template>
  <div class="auth-center">
    <div class="auth-center-top m-spill">
      <div class="auth-center-top-inner">
        <div class="auth-center-title">
          <h1 class="title flex cen-center font-heading-0">{{ "使用授权查询中心" }}</h1>
        </div>
        <div class="auth-form flex cen-center m-bottom-sm">
          <a-input
            v-model="license_id"
            class="code-input"
            size="large"
            placeholder="输入授权码"
            @keydown.enter="onSearch"
            @change="onSearch"
          >
            <span slot="addonAfter" @click="onSearch">
              <img src="@/assets/images/web/home/auth_search.svg"/>
              查询
            </span>
          </a-input>
        </div>
        <div class="code-case">
          <div class="default-code-box">
            <span>授权码示例：</span>
            <span class="cursor-pointer" @click="onClickCode(defaultCode)">{{defaultCode}}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="background:#fff;" v-if="license_id==''">
      <a-row class="container" style="padding:24px 0px;font-weight: 500;">
        <span style="display:block;font-size: 18px;">当有人对你的音乐授权产生质疑怎么办？</span>
        <a-col style="margin-top:24px;display:inline-block;height:290px;border-radius: 2px;border: 1px solid #E2E5E9;width:32%;">
          <a-col :span=24 style="text-align:center;padding-top:24px;">
            <span style="font-size: 18px;">01</span>
          </a-col>
          <a-col :span=24 style="text-align:left;padding:21px 7%;font-size: 14px;">
            <span>出示官方授权书，证明由Spark Music提供了使用授权</span>
          </a-col>
          <a-col :span=24 style="text-align:left;padding:0 7%;">
            <span>在Spark Music平台及其合作渠道购买音乐使用授权后，Spark Music会提供《授权书》作为授权证明。通过《授权书》中的授权码，可在Spark Music音乐授权查询中心查询到相关授权信息。</span>
          </a-col>
        </a-col>
        <a-col style="display:inline-block;height:290px;border-radius: 2px;border: 1px solid #E2E5E9;margin-left:2%;width:32%;margin-left:2%;">
          <a-col :span=24 style="text-align:center;padding-top:24px;">
            <span style="font-size: 18px;">02</span>
          </a-col>
          <a-col :span=24 style="text-align:left;padding:21px 7%;font-size: 14px;">
            <span>联系Spark Music获取更多音乐人授权证明</span>
          </a-col>
          <a-col :span=24 style="text-align:left;padding:0 7%;">
            <span>如果有人对《授权书》权威性存在质疑，请联系Spark Music平台，Spark Music会出示更多音乐人相关授权证明，帮助客户维权。</span>
          </a-col>
        </a-col>
        <a-col style="display:inline-block;height:290px;border-radius: 2px;border: 1px solid #E2E5E9;margin-left:2%;width:32%;margin-left:2%;">
          <a-col :span=24 style="text-align:center;padding-top:24px;">
            <span style="font-size: 18px;">03</span>
          </a-col>
          <a-col :span=24 style="text-align:left;padding:21px 7%;font-size: 14px;">
            <span>由Spark Music导致的版权纠纷，平台将承担相应的法律责任并赔偿损失</span>
          </a-col>
          <a-col :span=24 style="text-align:left;padding:0 7%;">
            <span>如有第三方创作者（非Spark Music音乐人）声称您对音乐作品的使用行为侵犯到其合法权益，请您及时联系Spark Music，我们将对作品创作者的身份进行核实。如果由于Spark Music审核工作的失误导致您陷入版权纠纷，Spark Music将赔偿您的损失并承担法律责任。</span>
          </a-col>
        </a-col>
      </a-row>
    </div>
    <div class="auth-result-wrap">
      <div class="result-content" id="iframeBox">
        <iframe class="iframe" :src="fileUrl" frameborder="0" @load="onLoadIframe"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
  let is_view = '1'; // 1 预览  2 下载

  export default {
    data() {
      return {
        license_id: "",
        // license_idInput: '',
        defaultCode: this.$config.DefaultAuthCode,
        vHtml: '',
        fileUrl: "",
      };
    },

    created() {
      let license_id = this.$route.query.license_id;
      let defaultCode = this.defaultCode;

      if (license_id === 'default') {
        this.license_id = defaultCode;
      } else if (license_id) {
        this.license_id = license_id;
      }

      this.goSearch(this.license_id);
    },

    mounted() {
      // this.createElement();
    },

    methods: {
      onSearch() {
        this.goSearch(this.license_id)
      },

      goSearch(value) {
        this.fileUrl = `${this.$axios.Auth_SearchAuth}?code=${value}&is_view=${is_view}`
      },

      onClickCode(str) {
        this.license_id = str;
        this.goSearch(this.license_id);
      },

      onLoadIframe(event) {
        console.log(event);
      },
      
      onProgress(event) {
        console.log(event);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .auth-result-wrap {
    width: 100%;
    overflow: auto;

    .result-content {
      width: 800px;
      height: 1200px;
      margin: 30px auto;

      .iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .auth-center-top {
    background-color: $back-color;
    padding: 40px 0;

    .auth-center-title {
      margin-bottom: 53px;
    }

    .auth-center-top-inner {
      width: 480px;
      margin: 0 auto;
    }
  }

  .auth-form {
    //border-bottom: 1px solid $black-2;
    background: #FFFFFF;
    border-radius: 30px;
    border: 1px solid #E2E5E9;
    height:40px;
    /deep/ {
      .ant-input {
        height: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border: none;
        background-color: transparent !important;
        &:focus,
        &:hover {
          border: none;
          box-shadow: none;
        }
      }
      .ant-input-group-addon{
        border-radius: 0px 30px 30px 0px;
        background: #333333;
        border: 1px solid #E2E5E9;
        color:#fff;
        height: 40px;
      }
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .auth-center-top {
      .auth-center-top-inner {
        width: 95%;
        margin: 0 3%;
      }
    }

    .default-code-box {
      > span {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
</style>
